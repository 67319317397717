$(function() {
  $('.tab01 li:first-child').on('click', function() {
    $(this).addClass('active');
    $('.newsList01:first-child').addClass('active');
    var thisContent01 = $(this);
    $('.tab01 li').not(thisContent01).removeClass('active');
    var thisContent02 = $('.newsList01:first-child');
    $('.newsList01').not(thisContent02).removeClass('active');
  });

  $('.tab01 li:nth-child(2)').on('click', function() {
    $(this).addClass('active');
    $('.newsList01:nth-child(2)').addClass('active');
    var thisContent01 = $(this);
    $('.tab01 li').not(thisContent01).removeClass('active');
    var thisContent02 = $('.newsList01:nth-child(2)');
    $('.newsList01').not(thisContent02).removeClass('active');
  });

  $('.tab01 li:nth-child(3)').on('click', function() {
    $(this).addClass('active');
    $('.newsList01:nth-child(3)').addClass('active');
    var thisContent01 = $(this);
    $('.tab01 li').not(thisContent01).removeClass('active');
    var thisContent02 = $('.newsList01:nth-child(3)');
    $('.newsList01').not(thisContent02).removeClass('active');
  });

  $('.tab01 li:nth-child(4)').on('click', function() {
    $(this).addClass('active');
    $('.newsList01:nth-child(4)').addClass('active');
    var thisContent01 = $(this);
    $('.tab01 li').not(thisContent01).removeClass('active');
    var thisContent02 = $('.newsList01:nth-child(4)');
    $('.newsList01').not(thisContent02).removeClass('active');
  });

  $('.tab01 li:nth-child(5)').on('click', function() {
    $(this).addClass('active');
    $('.newsList01:nth-child(5)').addClass('active');
    var thisContent01 = $(this);
    $('.tab01 li').not(thisContent01).removeClass('active');
    var thisContent02 = $('.newsList01:nth-child(5)');
    $('.newsList01').not(thisContent02).removeClass('active');
  });
});