$(function() {
	"use strict";
  // $('.allLink').on('click', function() {
  //   if($(this).find('a').attr('target', '_blank')) {
  //     window.open($(this).find('a').attr('href'), '_blank');
  //     return false;
  //   } else if($(this).find('a').attr()) {
  //     location.href = $(this).find('a').attr('href');
  //   }
  // });
  $('.allLinkBlank').on('click', function() {
    window.open($(this).find('a').attr('href'), '_blank');
    return false;
  });

  // if($('.allLink').find('a').length) {
  //   $('.allLink').on('click', function() {
  //     window.open($(this).find('a').attr('href'), '_blank');
  //     return false;
  //   });
  // }
  // if('.allLink:not(:has(a))') {

  // } else {
  //   $('.allLink').on('click', function() {
  //     window.open($(this).find('a').attr('href'), '_blank');
  //     return false;
  //   });
  // }

  $('.allLink').addClass('allLinkNone');

  $('.allLink').on('click', function() {
    if($(this).find('a').attr('target')=='_blank') {
      window.open($(this).find('a').attr('href'), '_blank');
    } else if($(this).find('a').length) {
      location.href = $(this).find('a').attr('href');
    } else {
    }
    return false;
  });

  $('.allLink').each(function() {
    if($(this).find('a').length) {
      $(this).removeClass('allLinkNone');
    }
  });
});