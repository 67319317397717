$('#news01 .selectAreaJs').change(function() {
  var selectAreaJs_val01 = $('#news01 .selectAreaJs').val();
  if(selectAreaJs_val01 == 'すべて') {
    $('#news01 li').show();
  }else if(selectAreaJs_val01 == '株式会社セントメディア') {
    $('.company02').show();
    $('#news01 li').not('.company02').hide();
  }else if(selectAreaJs_val01 == '株式会社エフエージェイ') {
    $('.company03').show();
    $('#news01 li').not('.company03').hide();
  }else if(selectAreaJs_val01 == '株式会社ボーダーリンク') {
    $('.company04').show();
    $('#news01 li').not('.company04').hide();
  }else if(selectAreaJs_val01 == '株式会社マーススポーツエージェント') {
    $('.company05').show();
    $('#news01 li').not('.company05').hide();
  }else if(selectAreaJs_val01 == '株式会社ネットジンザイバンク') {
    $('.company06').show();
    $('#news01 li').not('.company06').hide();
  }else if(selectAreaJs_val01 == '株式会社ヘルスケア・フィット') {
    $('.company07').show();
    $('#news01 li').not('.company07').hide();
  }else if(selectAreaJs_val01 == '株式会社クリエイティブバンク') {
    $('.company08').show();
    $('#news01 li').not('.company08').hide();
  }else if(selectAreaJs_val01 == 'ハイブリィド株式会社') {
    $('.company09').show();
    $('#news01 li').not('.company09').hide();
  }else if(selectAreaJs_val01 == '株式会社サムシングファン') {
    $('.company10').show();
    $('#news01 li').not('.company10').hide();
  }else if(selectAreaJs_val01 == 'WILLGROUPAsiaPacificPte.Ltd.') {
    $('.company11').show();
    $('#news01 li').not('.company11').hide();
  }else if(selectAreaJs_val01 == 'GoodJobCreations(Singapore)Pte.Ltd.') {
    $('.company12').show();
    $('#news01 li').not('.company12').hide();
  }else if(selectAreaJs_val01 == 'GJCMyanmarLtd.') {
    $('.company13').show();
    $('#news01 li').not('.company13').hide();
  }else if(selectAreaJs_val01 == 'DreamJobMyanmarLtd.') {
    $('.company14').show();
    $('#news01 li').not('.company14').hide();
  }else if(selectAreaJs_val01 == 'ScientecConsultingPte.Ltd.') {
    $('.company15').show();
    $('#news01 li').not('.company15').hide();
  }else if(selectAreaJs_val01 == 'BeathChapmanPte.Ltd.') {
    $('.company15').show();
    $('#news01 li').not('.company15').hide();
  }else if(selectAreaJs_val01 == 'AsiaRecruitHoldingsSdn.Bhd') {
    $('.company16').show();
    $('#news01 li').not('.company16').hide();
  }else if(selectAreaJs_val01 == 'OrientalAviationInternationalPte.Ltd.') {
    $('.company17').show();
    $('#news01 li').not('.company17').hide();
  }else if(selectAreaJs_val01 == 'OrientalAviationInternationalPte.Ltd.') {
    $('.company18').show();
    $('#news01 li').not('.company18').hide();
  }
});

$('#news02 .selectAreaJs').change(function() {
  var selectAreaJs_val01 = $('#news02 .selectAreaJs').val();
  if(selectAreaJs_val01 == 'すべて') {
    $('#news02 li').show();
  }else if(selectAreaJs_val01 == '株式会社セントメディア') {
    $('.company02').show();
    $('#news02 li').not('.company02').hide();
  }else if(selectAreaJs_val01 == '株式会社エフエージェイ') {
    $('.company03').show();
    $('#news02 li').not('.company03').hide();
  }else if(selectAreaJs_val01 == '株式会社ボーダーリンク') {
    $('.company04').show();
    $('#news02 li').not('.company04').hide();
  }else if(selectAreaJs_val01 == '株式会社マーススポーツエージェント') {
    $('.company05').show();
    $('#news02 li').not('.company05').hide();
  }else if(selectAreaJs_val01 == '株式会社ネットジンザイバンク') {
    $('.company06').show();
    $('#news02 li').not('.company06').hide();
  }else if(selectAreaJs_val01 == '株式会社ヘルスケア・フィット') {
    $('.company07').show();
    $('#news02 li').not('.company07').hide();
  }else if(selectAreaJs_val01 == '株式会社クリエイティブバンク') {
    $('.company08').show();
    $('#news02 li').not('.company08').hide();
  }else if(selectAreaJs_val01 == 'ハイブリィド株式会社') {
    $('.company09').show();
    $('#news02 li').not('.company09').hide();
  }else if(selectAreaJs_val01 == '株式会社サムシングファン') {
    $('.company10').show();
    $('#news02 li').not('.company10').hide();
  }else if(selectAreaJs_val01 == 'WILLGROUPAsiaPacificPte.Ltd.') {
    $('.company11').show();
    $('#news02 li').not('.company11').hide();
  }else if(selectAreaJs_val01 == 'GoodJobCreations(Singapore)Pte.Ltd.') {
    $('.company12').show();
    $('#news02 li').not('.company12').hide();
  }else if(selectAreaJs_val01 == 'GJCMyanmarLtd.') {
    $('.company13').show();
    $('#news02 li').not('.company13').hide();
  }else if(selectAreaJs_val01 == 'DreamJobMyanmarLtd.') {
    $('.company14').show();
    $('#news02 li').not('.company14').hide();
  }else if(selectAreaJs_val01 == 'ScientecConsultingPte.Ltd.') {
    $('.company15').show();
    $('#news02 li').not('.company15').hide();
  }else if(selectAreaJs_val01 == 'BeathChapmanPte.Ltd.') {
    $('.company15').show();
    $('#news02 li').not('.company15').hide();
  }else if(selectAreaJs_val01 == 'AsiaRecruitHoldingsSdn.Bhd') {
    $('.company16').show();
    $('#news02 li').not('.company16').hide();
  }else if(selectAreaJs_val01 == 'OrientalAviationInternationalPte.Ltd.') {
    $('.company17').show();
    $('#news02 li').not('.company17').hide();
  }else if(selectAreaJs_val01 == 'OrientalAviationInternationalPte.Ltd.') {
    $('.company18').show();
    $('#news02 li').not('.company18').hide();
  }
});

$('#news03 .selectAreaJs').change(function() {
  var selectAreaJs_val01 = $('#news03 .selectAreaJs').val();
  if(selectAreaJs_val01 == 'すべて') {
    $('#news03 li').show();
  }else if(selectAreaJs_val01 == '株式会社セントメディア') {
    $('.company02').show();
    $('#news03 li').not('.company02').hide();
  }else if(selectAreaJs_val01 == '株式会社エフエージェイ') {
    $('.company03').show();
    $('#news03 li').not('.company03').hide();
  }else if(selectAreaJs_val01 == '株式会社ボーダーリンク') {
    $('.company04').show();
    $('#news03 li').not('.company04').hide();
  }else if(selectAreaJs_val01 == '株式会社マーススポーツエージェント') {
    $('.company05').show();
    $('#news03 li').not('.company05').hide();
  }else if(selectAreaJs_val01 == '株式会社ネットジンザイバンク') {
    $('.company06').show();
    $('#news03 li').not('.company06').hide();
  }else if(selectAreaJs_val01 == '株式会社ヘルスケア・フィット') {
    $('.company07').show();
    $('#news03 li').not('.company07').hide();
  }else if(selectAreaJs_val01 == '株式会社クリエイティブバンク') {
    $('.company08').show();
    $('#news03 li').not('.company08').hide();
  }else if(selectAreaJs_val01 == 'ハイブリィド株式会社') {
    $('.company09').show();
    $('#news03 li').not('.company09').hide();
  }else if(selectAreaJs_val01 == '株式会社サムシングファン') {
    $('.company10').show();
    $('#news03 li').not('.company10').hide();
  }else if(selectAreaJs_val01 == 'WILLGROUPAsiaPacificPte.Ltd.') {
    $('.company11').show();
    $('#news03 li').not('.company11').hide();
  }else if(selectAreaJs_val01 == 'GoodJobCreations(Singapore)Pte.Ltd.') {
    $('.company12').show();
    $('#news03 li').not('.company12').hide();
  }else if(selectAreaJs_val01 == 'GJCMyanmarLtd.') {
    $('.company13').show();
    $('#news03 li').not('.company13').hide();
  }else if(selectAreaJs_val01 == 'DreamJobMyanmarLtd.') {
    $('.company14').show();
    $('#news03 li').not('.company14').hide();
  }else if(selectAreaJs_val01 == 'ScientecConsultingPte.Ltd.') {
    $('.company15').show();
    $('#news03 li').not('.company15').hide();
  }else if(selectAreaJs_val01 == 'BeathChapmanPte.Ltd.') {
    $('.company15').show();
    $('#news03 li').not('.company15').hide();
  }else if(selectAreaJs_val01 == 'AsiaRecruitHoldingsSdn.Bhd') {
    $('.company16').show();
    $('#news03 li').not('.company16').hide();
  }else if(selectAreaJs_val01 == 'OrientalAviationInternationalPte.Ltd.') {
    $('.company17').show();
    $('#news03 li').not('.company17').hide();
  }else if(selectAreaJs_val01 == 'OrientalAviationInternationalPte.Ltd.') {
    $('.company18').show();
    $('#news03 li').not('.company18').hide();
  }
});

$('#news04 .selectAreaJs').change(function() {
  var selectAreaJs_val01 = $('#news04 .selectAreaJs').val();
  if(selectAreaJs_val01 == 'すべて') {
    $('#news04 li').show();
  }else if(selectAreaJs_val01 == '株式会社セントメディア') {
    $('.company02').show();
    $('#news04 li').not('.company02').hide();
  }else if(selectAreaJs_val01 == '株式会社エフエージェイ') {
    $('.company03').show();
    $('#news04 li').not('.company03').hide();
  }else if(selectAreaJs_val01 == '株式会社ボーダーリンク') {
    $('.company04').show();
    $('#news04 li').not('.company04').hide();
  }else if(selectAreaJs_val01 == '株式会社マーススポーツエージェント') {
    $('.company05').show();
    $('#news04 li').not('.company05').hide();
  }else if(selectAreaJs_val01 == '株式会社ネットジンザイバンク') {
    $('.company06').show();
    $('#news04 li').not('.company06').hide();
  }else if(selectAreaJs_val01 == '株式会社ヘルスケア・フィット') {
    $('.company07').show();
    $('#news04 li').not('.company07').hide();
  }else if(selectAreaJs_val01 == '株式会社クリエイティブバンク') {
    $('.company08').show();
    $('#news04 li').not('.company08').hide();
  }else if(selectAreaJs_val01 == 'ハイブリィド株式会社') {
    $('.company09').show();
    $('#news04 li').not('.company09').hide();
  }else if(selectAreaJs_val01 == '株式会社サムシングファン') {
    $('.company10').show();
    $('#news04 li').not('.company10').hide();
  }else if(selectAreaJs_val01 == 'WILLGROUPAsiaPacificPte.Ltd.') {
    $('.company11').show();
    $('#news04 li').not('.company11').hide();
  }else if(selectAreaJs_val01 == 'GoodJobCreations(Singapore)Pte.Ltd.') {
    $('.company12').show();
    $('#news04 li').not('.company12').hide();
  }else if(selectAreaJs_val01 == 'GJCMyanmarLtd.') {
    $('.company13').show();
    $('#news04 li').not('.company13').hide();
  }else if(selectAreaJs_val01 == 'DreamJobMyanmarLtd.') {
    $('.company14').show();
    $('#news04 li').not('.company14').hide();
  }else if(selectAreaJs_val01 == 'ScientecConsultingPte.Ltd.') {
    $('.company15').show();
    $('#news04 li').not('.company15').hide();
  }else if(selectAreaJs_val01 == 'BeathChapmanPte.Ltd.') {
    $('.company15').show();
    $('#news04 li').not('.company15').hide();
  }else if(selectAreaJs_val01 == 'AsiaRecruitHoldingsSdn.Bhd') {
    $('.company16').show();
    $('#news04 li').not('.company16').hide();
  }else if(selectAreaJs_val01 == 'OrientalAviationInternationalPte.Ltd.') {
    $('.company17').show();
    $('#news04 li').not('.company17').hide();
  }else if(selectAreaJs_val01 == 'OrientalAviationInternationalPte.Ltd.') {
    $('.company18').show();
    $('#news04 li').not('.company18').hide();
  }
});