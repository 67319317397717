var dbTable1 = null;

function page() {
    let pageLength = parseInt($("#news_pageLength").val(), 10);

    dbTable1 = $('#newsTable01').DataTable({
        ordering: false,//disable ordering of columns
        pageLength  : pageLength,//1ページで表示するお知らせの数
        pagingType: "full_numbers",//ページネーションのタイプ
        dom: 'tp',//html表示時、テーブルとページネーションの位置を調整
        language: {
            paginate: {
                first: "«",
                previous: "< 前の"+pageLength+"件",
                next: "次の"+pageLength+"件 >",
                last: "»"
            },
            zeroRecords: "リリースがありません"//検索結果0件の時の文言
        },
        initComplete : function(settings) {//テーブル、データが全部準備できた後実行する
            $(".newsDetails.beforeLoaded").removeClass("beforeLoaded");//テーブルを表示する。
        }
    });
}
function changeList() {
    let news_year = $('#news_year_val').val();
    let news_group = $("#news_group").val();
    let news_maker = $("#news_maker").val();
    let news_url = $("#news_url").val();

    $.ajax({
        type: 'POST',
        url: news_url,
        dataType: 'html',
        data: {
            action: 'filter_news',
            terms: `${news_year}`,
            group: `${news_group}`,
            maker: `${news_maker}`
        },
        success: function(res) {
            if ($.fn.dataTable.isDataTable('#newsTable01')) {
                dbTable1.destroy();
            }
            $('.newsData').html("");
            $('.newsData').append(res);
            page();
        }
    });
}
$("#news_year li").click((el)=> {
    $('#news_year_val').val(el.target.dataset.year);
    changeList();
});
$("#news_group").change(()=> {
    changeList();
});
$("#news_maker").change(()=> {
    changeList();
});
window.onload = ()=> {
    $('#news_year_val').val($('#news_year .active').attr("data-year"));
    changeList();
};