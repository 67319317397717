// $(function(){
//   $('.hoverImg img.pcOnly').hover(function(){
//     $(this).attr('src', $(this).attr('src').replace('_off', '_on'));
//   }, function(){
//     if (!$(this).hasClass('currentPage')) {
//       $(this).attr('src', $(this).attr('src').replace('_on', '_off'));
//     }
//   });
// });

$(function(){
  $('.hoverImg').find('.pcOnly').each(function() {
    var onImage = $(this).attr('src').replace('_off', '_on');
    this.onHtml = $('<img src="' + onImage + '" alt="" class="pcOnly" style="position:absolute; opacity:0;" />');
    $(this).before(this.onHtml);

    $(this.onHtml).hover(function() {
      $(this).stop().animate({'opacity': '1'}, 400);
    }, function(){
      $(this).stop().animate({'opacity': '0'}, 400);
    });
  });

  $('.hoverImg02').find('.pcOnly').each(function() {
    var onImage = $(this).attr('src').replace('_off', '_on');
    this.onHtml = $('<img src="' + onImage + '" alt="" class="pcOnly pcOn01" style="position:absolute; opacity:0;" />');
    $(this).before(this.onHtml);

    $('.hoverImg02').hover(function() {
      $(this).find('.pcOn01').stop().animate({'opacity': '1'}, 400);
    }, function(){
      $(this).find('.pcOn01').stop().animate({'opacity': '0'}, 400);
    });
  });
});