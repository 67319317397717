/** @format */
import "../scss/all.scss";

import "./components/ajaxzip3.js";
import "./components/all-link.js";
import "./components/colorBox.js";
import "./components/height.js";
import "./components/hoverIgg.js";
import "./components/hoverlinktext.js";
import "./components/newsTabselect.js";
import "./components/page.js";
import "./components/smooth-scroll.js";
import "./components/spmenu.js";
import "./components/tab.js";
import "./components/XjStorageLoaderLibrary04.js";
import "./contents/news.js";
import "./contents/accordion.js";

$(window).on("load", function () {
    // サイドバーでactiveな項目を判別
    const _sidebarActiveClass = (id, number) => {
        $(id).each((index, elem) => {
            if ($(elem).attr("href").split("/")[number] === location.href.split("/")[number]) {
                $(elem).parent().addClass("active");
            } else if ($(elem).attr("href") + "/" === location.href) {
                $(elem).parent().addClass("active");
            }
        });
    };

    _sidebarActiveClass("#js-active-sidebar a", 4);
    // サステナビリティのsocial,governance対象
    _sidebarActiveClass("#js-active-sidebar-child a", 5);
});
