$(function(){
  if(document.URL.match('/profile')) {
    $('.header .mainMenu01 li:first-child, .footer .mainMenu01 li:first-child').addClass('active');
  } else if (document.URL.match('/ir')) {
    $('.header .mainMenu01 li:nth-child(2), .footer .mainMenu01 li:nth-child(2)').addClass('active');
  } else if (document.URL.match('/sustainability')) {
    $('.header .mainMenu01 li:nth-child(3), .footer .mainMenu01 li:nth-child(3)').addClass('active');
  } else if (document.URL.match('/recruit')) {
    $('.header .mainMenu01 li:nth-child(4), .footer .mainMenu01 li:nth-child(4)').addClass('active');
  } else if (document.URL.match('/mt/plugins/MailForm/mt-mail-form.cgi') || document.URL.match('/mailform')) {
    $('.header .mainMenu01 li:last-child, .footer .mainMenu01 li:last-child').addClass('active');
  } else {
    $('.header .mainMenu01 li').removeClass('active');
  }
});
