//========================================================
//
// ■XJStorageLoaderクラス定義
//
//========================================================

if (!Array.prototype.indexOf)
{
	Array.prototype.indexOf = function(elt /*, from*/)
	{
		var len = this.length;

		var from = Number(arguments[1]) || 0;
		from = (from < 0)
				 ? Math.ceil(from)
				 : Math.floor(from);
		if (from < 0)
			from += len;

		for (; from < len; from++)
		{
			if (from in this &&
					this[from] === elt)
				return from;
		}
		return -1;
	};
}

(function() {

	/*========================================================
	 コンストラクタ
	========================================================*/
	XjStorageLoaderKessan = function(s)
	{
		var defaults =
		{
			domain:'//www.xj-storage.jp',
			company:'AS02826',
			pdf:'1',
			len:'',
			documents_all:'',
			documents_Fresults:'34,91,96,1900'
		};

		this.settings = $.extend ( defaults, s ) ;
		this.fdate;
		this.pdate;
		this.documents;
		this.select_latest = '';
		this.select_oldest = '';

		this.ary_doc_no ;
		this.ary_doc_Fresults ;

		XjStorageLoaderKessan.prototype.init.call(this);
 	};

	/*========================================================
	 初期設定
	========================================================*/
	XjStorageLoaderKessan.prototype.init = function()
	{


		$.ajaxSetup({scriptCharset:'utf-8'});

		this.ary_doc_Fresults	= this.settings.documents_Fresults.split ( "," ) ;	
		
		this.settings.documents_all =
					this.settings.documents_Fresults  ;
				
		//初期読み込み対象指定
		this.documents = this.settings.documents_all;
		this.ary_doc_no = this.documents.split ( "," ) ;

		//記事年範囲取得
		this.setMaxMinYear ( this.documents ) ;
	};

	/*========================================================
	 年選択ボタン生成
	========================================================*/
	XjStorageLoaderKessan.prototype.setMaxMinYear = function(code)
	{
		var self = this;
		var xjurl = '';

		//URL生成
		xjurl += self.settings.domain + '/public-list/GetList.aspx?company=';
		xjurl += self.settings.company;
		xjurl += '&len=10000';
		xjurl += '&doctype=' + this.documents;
		xjurl += '&output=json&callback=?';

		$.ajax (
		{
			url: xjurl,
			dataType: 'json',

			success : function ( data )
			{
				if ( undefined == data.items )
				{
					return ;
				}

				$.each ( data.items, function ( i, item )
				{

					// 目的の文書番号でなかったら
					if ( -1 == self.ary_doc_no.indexOf ( item.disclosureCode ) )
					{
						return true;
					}

					var date = item.publishDate.split(' ')[0].split('/');
					// 年度の算出
					entry_year = self.getFiscalYear ( date ) ;

					//最新の年月期と最古の年月期を取得
					if ( self.select_latest === '' || self.select_latest < entry_year )
					{
						self.select_latest = entry_year ;
					}

					if ( self.select_oldest === '' || self.select_oldest > entry_year )
					{
						self.select_oldest = entry_year ;
					}
				});

			},
			complete : function()
			{
				self.setDateButton ( ) ;
				//self.setDocumentButton ( ) ;
				self.show ( ) ;
			}
		}
		);
	}

	/*========================================================
	7/1～ 日付から→20XX年Y月期
	========================================================*/
	XjStorageLoaderKessan.prototype.getFiscalYear = function ( date )
	{
		var self = this ;
		var fiscalyear = '' ;
		var res ;

		var next_y = String ( Number ( date[ 0 ] ) + 1 ) ;
		var dt_s = Date.parse ( date[ 0 ] + '/7/1' ) ;
		var dt_m = Date.parse ( date[ 0 ] + '/12/31' ) ;
		var dt = Date.parse ( date[ 0 ] + '/' + date[ 1 ] + '/' + date[ 2 ] ) ;

		if ( dt_s <= dt && dt_m >= dt )
		{
			fiscalyear = Number ( date[ 0 ] ) +1 ;
		}
		else
		{
			fiscalyear = Number ( date[ 0 ] ) ;
		}

		return fiscalyear ;
	}

	/*============================================
	 空白除去
	============================================*/
	XjStorageLoaderKessan.prototype.spaceTrim = function(str) {
		return str.replace(/[ 　\t\r\n]+/g, "");
	}

	/*============================================
	 全角英数を半角に変換
	============================================*/
	XjStorageLoaderKessan.prototype.zen2han = function(str){
		return str.replace(/[０-ｚ]/g,function($0){
			return String.fromCharCode(parseInt($0.charCodeAt(0))-65248);
		});
	}

	/*============================================
	 平成を西暦に変換
	============================================*/
	XjStorageLoaderKessan.prototype.heiseiToSeireki = function ( y )
	{
		if ( ( y > 0 ) && ( y < 99 ) )
		{
			 return ( 1988 + y ) ;
		}
	}

	/*============================================
	 西暦を平成に変換
	============================================*/
	XjStorageLoaderKessan.prototype.SeirekiToHeisei = function ( y )
	{
		if ( y > 1988 )
		{
			return ( y - 1988 ) ;
		}

		return y ;
	}


	/*========================================================
	 年選択ボタン生成
	========================================================*/
	XjStorageLoaderKessan.prototype.setDateButton = function ( )
	{
		var self = this ;

		var ul = $( '#xj-select-year_s' ) ;
		var tmp = $( '<option value="">決算期を選択してください</option>' ) ;
		ul.append ( tmp ) ;

		//年範囲分年メニュー生成
		for ( var i = self.select_latest; i >= self.select_oldest; i-- )
		{
			if ( i !== ''){
				if ( i == self.active_select )
				{
					tmp = $( '<option value="' + i + '" selected>' + i + '年3月期</option>' ) ;
				}
				else
				{
					tmp = $( '<option value="' + i + '">' + i + '年3月期</option>' ) ;
				}
			}
			else { $("#xj-select-year_s").css('display','none') }

			ul.append ( tmp ) ;
		}

		ul.change ( function ( )
		{
			var year = $( '#xj-select-year_s option:selected' ).val ( ) ;

			$( '#xj-select-year_s').val ( year ) ;

			if ( "" == year )
			{
				self.fdate = "" ;
				self.pdate = "" ;
				self.setDuration ( year, year ) ;
				return ;
			}
			self.setDuration ( year, year ) ;
			//self.setDuration ( year + '1231', year + '0101' ) ;
		}) ;
	}	

	/*========================================================
	 絞込み期間更新
	========================================================*/
	XjStorageLoaderKessan.prototype.setDuration = function ( f, p )
	{
		this.fdate = f;
		this.pdate = p;
		this.show();
	}

	/*========================================================
	 絞込みカテゴリー更新
	========================================================*/
	XjStorageLoaderKessan.prototype.setDocument = function ( d )
	{
		this.documents = d;
		this.show();
	}

	/*========================================================
	 表示処理
	========================================================*/
	XjStorageLoaderKessan.prototype.show = function()
	{
		var self = this;
		var is_first = true;

		var url =  this.settings.domain + '/public-list/GetList.aspx?company=';

		url += this.settings.company;

		// タイプ指定
		if ( this.documents && this.documents.length > 0 )
		{
			url+= '&doctype=' + this.documents;
		}
		//url+= '&filetype=PDF-GENERAL';
		url+= '&output=json&callback=?';
		

		$.getJSON ( url, function ( data )
		{
			$('#xj-mainlist').empty ( ) ;

			var cont = '' ;
			var last_year = 0 ;
			var table_cont ;
			var intable_cont ;
			var in_cont = '' ;

			if ( data.items )
			{

				var j = 0 ;
				var now_dd = new Date ( ) ;
				
				$.each ( data.items, function ( i, item )
				{
					
					// 目的の文書番号でなかったら
					if ( -1 == self.ary_doc_no.indexOf ( item.disclosureCode ) )
					{
						return true;
					}
					
					var date = item.publishDate.split(' ')[0].split('/');
					
					// 年度の算出
					var entry_year = self.getFiscalYear ( date ) ;

					if ( ( '' == self.fdate || !self.fdate ) )
					{
						if ( last_year != entry_year )
						{
							j++ ;
						}

						// 最新2期分を表示
						if ( 3 <= j )
						{
							return true ;
						}
					}

					else if ( self.fdate != entry_year )
					{
						return true ;
					}
					
					// 年度が替わったら
					if ( last_year != entry_year )
					{
						if ( intable_cont )
						{
							cont += intable_cont[0].outerHTML ;
						}

						last_year = entry_year ;

						cont += '<h3 class="title02 title09">' + String ( last_year ) + '年3月期</h3>' ;
						table_cont = $( '<ul></ul>' ) ;
						intable_cont = $( '<div class="tab-content"></div>' ) ;
						
					}

					var entrytype = '';
					var url = '';
					var size = '';
					var page = '';

					if ( item.files )
					{
						$.each ( item.files, function ( j, file )
						{
							if ( file.type == 'PDF-GENERAL')
							{
								url = file.url ;
								size = parseInt ( file.size ) ;
								page = file.page ;
							}
						});
					}

					// サイズ設定
					if ( size > 0 )
					{
						if ( size > 1000 && size < 1000000 )
						{
							size = parseInt ( size / 1000 ) + 'K' ;
						}
						else if ( size > 1000000 )
						{
							size = parseInt ( size / 1000000 ) + 'M' ;
						}
					}
					else
					{
						size = '－' ;
					}



					// 日付設定
					var date = item.publishDate.split(' ')[0].split('/');
					var dateStr = date[0] + '.' + date[1] + '.' + date[2] ;
					
					

					// 画面表示

					in_cont = '' ;
					
					in_cont += '<li class="allLinkBlank clearfix"><div class="left clearfix">' ;
					
					in_cont += '<p class="date">' + dateStr + '</p>' ;
					
					if ( url != '' && size != '－' )
					{
					in_cont += '<p class="pdf spOnly"><img src="../../common/images/ico_common07.png" alt="pdf"> (PDF: ' + size + 'B)</p>' ;
					}
					
					in_cont +='</div>' ;
					
					in_cont += '<div class="left clearfix">';
					
					in_cont += '<p class="spOnly comment">';

					if ( '' != url )
					{
						in_cont += '<a target="_blank" href="' + url + '">' ;
					}

					in_cont += item.title ;
					
					if ( url != '' )
					{
						in_cont += '<span class="win"><img src="../../common/images/ico_common14.png" alt="win"></span>' ;
					}
					
					if ( url != '' )
					{
						in_cont += '</a>' ;
					}
					
					in_cont +='</p>' ;
					
					in_cont += '<p class="comment pcOnly">';
					
					if ( '' != url )
					{
						in_cont += '<a target="_blank" href="' + url + '">' ;
					}

					in_cont += item.title ;
					
					if ( url != '' && size != '－' )
					{
						in_cont += '<span class="pdf pcOnly"><img src="../../common/images/ico_common07.png" alt="pdf"> (PDF: ' + size + 'B)</span>' ;
					}
					else if ( url != '' )
					{
						in_cont += '<span class="win"><img src="../../common/images/ico_common14.png" alt="win"></span>' ;
					}
					
					if ( url != '' )
					{
						in_cont += '</a>' ;
					}
					
					in_cont +='</p>' ;

					in_cont += '</div></li>' ;

					table_cont.append ( in_cont ) ;
					intable_cont.append ( table_cont) ;
					});
					
					if ( intable_cont )
				{
					cont += intable_cont[0].outerHTML ;
				}
			}
			if ( '' == cont )
			{
				cont += '<li class="clearfix">ただいま掲載する事項はございません。</li>' ;
			}
			
			$('#xj-mainlist').html(cont);
		});
	}

}());