$(function() {
  $('#spBtn').on('click', function() {
    if ($('#menu').css('display') == 'none') {
      $('#menu').stop().fadeIn();
      $(this).addClass('active');
      $(this).children('.text01').text('閉じる');
    } else {
      $('#menu').fadeOut();
      $(this).removeClass('active');
      $(this).children('.text01').text('メニュー');
    }
  });

  //windowの幅をxに代入
  var x = window.innerWidth;
  //windowの分岐幅をyに代入
  var y = 767;
  if (x <= y) {
    $('.footer .group01 .title02').addClass('groupBtn01');
  }else{
    $('.footer .group01 .title02').removeClass('groupBtn01');
  }

  $(window).on('resize', function(){
    //windowの幅をxに代入
    var x = window.innerWidth;
    //windowの分岐幅をyに代入
    var y = 767;
    if (x <= y) {
      $('.footer .group01 .title02').addClass('groupBtn01');
    }else{
      $('.footer .group01 .title02').removeClass('groupBtn01');
    }
  });

  $('.footer .group01 .groupBtn01').on('click', function() {
    $(this).toggleClass('active');
    $(this).next('ul').slideToggle();
  });
});