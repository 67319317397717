$(function() {
  // スクロールのオフセット値
  var offsetY = -10;
  // スクロールにかかる時間
  var time = 500;

  var pagetop = $('.pageTop');
  pagetop.hide();
  //スクロールが100に達したらボタン表示
  $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
          pagetop.fadeIn();
      } else {
          pagetop.fadeOut();
      }
  });

  pagetop.click(function () {
    $('body, html').animate({ scrollTop: 0 }, 500);
    return false;
  });

  $('.smoothScroll').click(function() {
    // スクロールの速度
    var speed = 400; // ミリ秒
    // アンカーの値取得
    var href= $(this).attr("href");
    // 移動先を取得
    var target = $(href === "#" || href === "" ? 'html' : href);
    // 移動先を数値で取得
    var position = target.offset().top;
    // スムーススクロール
    $('body,html').animate({scrollTop:position}, speed, 'swing');
    return false;
  });

  // var nav = $('.mainMenuTop');
  // //navの位置
  // if ( nav.length ) {
  //   var navTop = nav.offset().top;
  // }
  // //スクロールするたびに実行
  // $(window).scroll(function () {
  //   var winTop = $(this).scrollTop();
  //   //スクロール位置がnavの位置より下だったらクラスfixedを追加
  //   if (winTop >= navTop) {
  //     nav.addClass('fixed');
  //   } else if (winTop <= navTop) {
  //     nav.removeClass('fixed');
  //   }
  // });
});